import React from 'react';
import './Article.css';
import article from '../../articles/A-Neurocognitive-Model-for-Collision-Avoidance-in-Humans.pdf';

class Article extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: props.title,
			category: props.category,
            date: props.date,
            link: props.link,
		}
	}

    render() {
    	const { onRouteChange } = this.props;
    	return (
    		<div className="heading-1" onClick={ () => { onRouteChange(this.state.title) }}>
    			<h3 className="heading-2"> {this.state.date} </h3>
    			<h1 className="heading-3"> 
                    <a className="a2" href={article}> {this.state.title} </a> 
                </h1>
    		</div>
		);
    }
 }

export default Article;
  
import React from "react";
import image from '../../babel.jpeg'
import './Image.css'

const Image = () => {
	return (
		<div> 
			<img className="image" alt='image' src={image}/>
		</div>
	);
}

export default Image;

import React from 'react';
import './Filter.css';

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryLabel: props.categoryLabel
		}
	}

	render() {
		const { onCategorySelection } = this.props;
		return (
		<div className="a1">
			<ul>
      			<li> <a onClick={() => onCategorySelection(this.state.categoryLabel)} className="allsides-6" href="javascript:void();"> {this.state.categoryLabel} </a> </li>
      		</ul>
    	</div>
    	);
	}
}

export default Filter;
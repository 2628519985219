import React, { Component } from 'react';
import './App.css';
import Image from './components/Image/Image';
import Divider from './components/Divider/Divider';
import Filter from './components/Filter/Filter';
import Article from './components/Article/Article';

class App extends Component {
  constructor() {
    super();
    this.state = {
      route: '',
      category: '',
    }
  }

  onRouteChange = (route) => {
    this.setState({route: route});
  }
  onCategorySelection = (category) => {
    this.displayArticles(category);
  }
  componentDidMount() {
    this.displayArticles("");
  }

  displayArticles = async (category) => {

    var articles = [];

    var categories = ["Brains", 
                      "Space", 
                      "Philosophy", 
                      "Politics", 
                      "Physics", 
                      "Math", 
                      "Archived"];

    var articleTitles = ["A Neurocognitive Model for Collision Avoidance in Humans"];
    var articleCategories = [categories[0]];
    var articleDates = ["2021 Oct 17"];
    var articleLinks = [];

   if (category !== "") {
        var indices = articleCategories.map((e, i) => e === category ? i : '').filter(String);
        var len = indices.length;
    } else {
        var len = articleTitles.length;
        var indices = Array.from(Array(len).keys())
    }

    for (var i = 0; i < len; i++) {
      articles.push(<Article onRouteChange={this.onRouteChange} title={articleTitles[indices[i]]} category={articleCategories[indices[i]]} date={articleDates[indices[i]]} link={articleLinks[indices[i]]}/>);
    }

    this.setState({
      Articles: articles
    })
  }

  render() {
    return (
      <div>
        <Image/>
        <div className="filter-bar">
          <Divider/>
          <div className="filter-text">
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Brains"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Space"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Philosophy"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Politics"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Physics"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Math"}/>
            <Filter onCategorySelection={this.onCategorySelection} categoryLabel={"Archived"}/>
          </div>
          <Divider/>
          <div className="article-list">
            {this.state.Articles}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
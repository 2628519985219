
import React from 'react';
import './Divider.css';

const Divider = () => {
	return (
    <div>
      <hr className="pill"/>
    </div>
	);
}

export default Divider;